import { z } from "zod"

export const password = z.string().refine(
  (value) => {
    const trimmedValue = value.trim()
    return trimmedValue.length >= 16 && trimmedValue.length <= 100
  },
  { message: "Must be at least 16 characters and less than 100" }
)

export const RegularLoginInput = z.object({
  email: z.string().email(),
  password: z.string(),
})

export const GoogleLoginInput = z.object({
  googleUser: z.boolean(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
})

export const LoginInput = z.union([RegularLoginInput, GoogleLoginInput]).nullable()
export type LoginInputType = z.infer<typeof LoginInput>
export type RegularInputType = z.infer<typeof RegularLoginInput>
export type GoogleInputType = z.infer<typeof GoogleLoginInput>

export const ForgotPasswordInput = z.object({
  email: z.string().email(),
})
export type ForgotPasswordInputType = z.infer<typeof ForgotPasswordInput>

export const ResetPasswordInput = z
  .object({
    password: password,
    passwordConfirmation: password,
    token: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords don't match",
    path: ["passwordConfirmation"], // set path of error
  })
export type ResetPasswordInputType = z.infer<typeof ResetPasswordInput>

export const SignupInput = z.object({
  email: z.string().email(),
  password,
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  address1: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipCode: z.string().optional(),
  smsOptIn: z.boolean().optional(),
  token: z.string().optional(),
})
export type SignupInputType = z.infer<typeof SignupInput>

export const ImpersonateUserInput = z.object({
  userId: z.number(),
})
export type ImpersonateUserInputType = z.infer<typeof ImpersonateUserInput>

export const InviteOrgInput = z.object({
  userId: z.number(),
  email: z.string().email(),
})
export type InviteOrgInputType = z.infer<typeof InviteOrgInput>

export const BannerNotificationInput = z.object({
  bannerMsg: z.string().optional().nullable(),
})
export type BannerNotificationInputType = z.infer<typeof BannerNotificationInput>
