import getBannerMsg from "app/tools/queries/getBannerMsg"
import { useQuery } from "blitz"

export const BannerNotification = () => {
  const [bannerMsg] = useQuery(getBannerMsg, {}, { suspense: false })

  if (!bannerMsg) return null

  return (
    <div className="px-4 py-2 text-center font-semibold" style={{ backgroundColor: "#ffad41" }}>
      {bannerMsg}
    </div>
  )
}
