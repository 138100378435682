/* eslint-disable import/first */
// Tracking must be imported first
import { Tracking } from "app/core/tracking"
import {
  AppProps,
  useSession,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  ErrorBoundary,
} from "blitz"
import LoginForm from "app/auth/components/LoginForm"
import "@reach/dialog/styles.css"
import "app/core/styles/index.css"
// TODO - convert test result reports to tailwind
import "app/test-results/styles.scss"
import Sentry from "integrations/sentry"
import ErrorComponent from "app/core/components/ErrorComponent"
import { Suspense, useEffect } from "react"
import SuperJson from "superjson"
import { BannerNotification } from "app/core/components/BannerNotification"

SuperJson.allowErrorProps("type")

export default function App({ Component, pageProps, err }: AppProps & { err: any }) {
  const session = useSession({ suspense: false })
  const getLayout = Component.getLayout || ((page) => page)

  useEffect(() => {
    if (session.userId) {
      Tracking.setUserId(session.userId)
    }
    if (session.orgId) {
      Tracking.setOrgId(session.orgId)
    }
  }, [session])

  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        Sentry.captureException(error, { contexts: { react: { componentStack } } })
      }}
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
    >
      <BannerNotification />
      {getLayout(<Component {...pageProps} err={err} />)}
    </ErrorBoundary>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error.name === "AuthenticationError") {
    return <LoginForm onSuccess={resetErrorBoundary} />
  } else if (error.name === "AuthorizationError") {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent
        statusCode={error.statusCode || 400}
        title={error.message || error.name || "Unknown error"}
      />
    )
  }
}
